import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

const findLogo = (logos, name) => {
  if (!name) {
    return
  }

  const logo = logos.edges.find(e => e.node.name === name)
  return logo ? logo.node : null
}

const BlogElement = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rhythm(2)};

  ${({ theme }) => `${theme.breakpoints.mobile} {
    flex-direction: column;
  }`}
`

const BlogLogo = styled.img`
  height: auto;
  width: 100px;
  margin-right: ${rhythm(1)};
  margin-bottom: 0;
  flex-shrink: 0;

  ${({ theme }) => `${theme.breakpoints.mobile} {
    margin: 0 0 ${rhythm(1)} 0;
  }`}
`

const BlogElementTitle = styled.h3`
  margin-top: 0;
  margin-bottom: ${rhythm(1 / 4)};
`

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout>
        <SEO
          lang="en"
          title="All posts"
          keywords={[
            `blog`,
            `gatsby`,
            `javascript`,
            `react`,
            'jonas pauthier',
            'reasonml',
            'hapijs',
            'nodejs',
          ]}
        />

        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const { logo, serie } = node.frontmatter

          return (
            <BlogElement key={node.fields.slug}>
              {logo ? (
                <BlogLogo src={findLogo(data.allFile, logo).publicURL} />
              ) : null}
              <div>
                <BlogElementTitle>
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </BlogElementTitle>
                {serie ? <small>{serie}, </small> : null}
                <small>{node.frontmatter.date}</small>
                <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
              </div>
            </BlogElement>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            serie
            logo
          }
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/logos/" } }) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`
